import { GetReadinessAdvisoryResponse } from '@doc/type'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

interface ComponentProps {
    readinessAdvisoryData: GetReadinessAdvisoryResponse['data'] | undefined
}

const MarkdownTextField = ({ readinessAdvisoryData } : ComponentProps) => {
    const forMarkdownContent = _.isString(readinessAdvisoryData?.advisoryData.body)
        ? readinessAdvisoryData?.advisoryData.body
            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
            .replace(/~(\d+)~/g, '<sub>$1</sub>')
            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
        : ''

    return <div className={'course-interface h-100'} >
        <div className={'container'}>
            <div className={'row  align-items-center advice-container'}>
                <div className={'col-auto'}>
                    <i className={'fas fa-stethoscope'}></i>
                </div>
                <div className={'col flex-column'}>
                    <div className={'title'}>{
                        readinessAdvisoryData?.advisoryData.header
                    }</div>
                    <div className={'subtitle'}>{
                    }</div>
                </div>
            </div>
        </div>

        <div className={'mb-2'}>
            <div className={'markdown fw-lighter mb-1 mt-3'}>
                {/* use workaround for  == to <mark></mark> */}
                <ReactMarkdown
                    components={{
                        // supersub replaces markdown with del tags
                        // for somereason.
                        del: (props) => <sub {...props} />,
                        ul: (props) => {
                            const modifiedProps = { ...props }
                            // eslint-disable-next-line react/prop-types
                            modifiedProps.ordered = props.ordered.toString() as any

                            if (modifiedProps.className && modifiedProps.className
                                .includes('contains-task-list')) {
                                return <ul
                                    {...modifiedProps}
                                    className={[
                                        'contains-task-list list-unstyled ps-4'
                                    ].join(' ')}
                                />
                            } else {
                                return <ul
                                    {...modifiedProps}

                                />
                            }
                        }
                    }}
                    linkTarget={'_blank'}
                    remarkPlugins={[
                        remarkBreaks,
                        remarkGfm,
                        supersub,
                        remarkEmoji,
                        remarkDefinitionList,
                        remarkHeadingId
                    ]} rehypePlugins={[
                        rehypeRaw
                    ]}
                >
                    {`${ forMarkdownContent }`}
                </ReactMarkdown>
            </div>
        </div>
    </div>
}

export default MarkdownTextField
