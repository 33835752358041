import { useAppSelector } from '@app/app.hook'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'

import { selectStylesheets } from '@stylesheet/slice'
import { Chart, ChartOptions, LegendOptions, TitleOptions, TooltipOptions } from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import { useEffect, useRef } from 'react'

import { selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { GetPatientReadinessSpiderResponse } from '@doc/type'
import Color from 'color'
import _ from 'lodash'

type ActiveChartType = Chart<'radar', number[]>;

interface ComponentProps {
    getPatientReadinessSpiderResponse: GetPatientReadinessSpiderResponse | undefined
}

// we just need the data
const RadarChart = ({ getPatientReadinessSpiderResponse }: ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)
    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)

    useEffect(() => {
        // Data and options for the chart

        const readinessData = getPatientReadinessSpiderResponse?.data.readinessData || []
        const readinessCategories = getPatientReadinessSpiderResponse?.data.readinessCategories || []

        // sheet to use.
        let sheetToUse = IKH_ADMIN_COLORS

        if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
            sheetToUse = IKH_ADMIN_COLORS
        } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
            sheetToUse = RTW_ADMIN_COLORS
        }

        const legendPlugin: | _DeepPartialObject<LegendOptions<'radar'>> | undefined = {
            display: true,
            position: 'top',
            align: 'start'
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'radar'>>| undefined = {
            enabled: true
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            display: false
        }

        const options: ChartOptions<'radar'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin

            },
            layout: {
                autoPadding: false,
                padding: -10
            },
            scales: {
                r: {
                    min: 0,
                    max: 100,
                    beginAtZero: true,
                    ticks: {
                        display: true

                    },
                    grid: {
                        display: true
                    }
                }
            }
        }

        const labels: string[] = _.map(readinessCategories, (o) => {
            let str = 'not-translated'

            switch (o) {
                case 'physical':
                    str = strings.doc?.text.careprofessionals.pt_readiness.physical || o
                    break
                case 'mental':
                    str = strings.doc?.text.careprofessionals.pt_readiness.mental || o
                    break
                case 'time':
                    str = strings.doc?.text.careprofessionals.pt_readiness.time || o
                    break
                case 'knowledge':
                    str = strings.doc?.text.careprofessionals.pt_readiness.knowledge || o
                    break
                case 'hospital':
                    str = strings.doc?.text.careprofessionals.pt_readiness.hospital || o
                    break
                case 'compliance':
                    str = strings.doc?.text.careprofessionals.pt_readiness.compliance || o
                    break
                case 'social':
                    str = strings.doc?.text.careprofessionals.pt_readiness.social || o
                    break
                case 'logistics':
                    str = strings.doc?.text.careprofessionals.pt_readiness.logistics || o
                    break
                case 'lifestyle':
                    str = strings.doc?.text.careprofessionals.pt_readiness.lifestyle || o
                    break
                default:
                    break
            }

            return _.capitalize(str)
        })
        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'radar',
                    data: {
                        // labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        labels,
                        datasets: _.map(readinessData, (o) => {
                            let colorToUse = sheetToUse.dark

                            if (o.type === 'predicted') {
                                colorToUse = sheetToUse.orange
                            } else if (o.type === 'actual') {
                                colorToUse = sheetToUse.blue
                            }

                            // colorToUse = sheetToUse.dark

                            const backgroundColor = Color(colorToUse).alpha(0.2).rgb().toString()
                            const borderColor = Color(colorToUse).alpha(0.5).rgb().toString()
                            const pointBorderColor = colorToUse
                            const pointBackgroundColor = colorToUse

                            return {
                                label: _.capitalize(o.type),
                                data: o.spiderData,
                                borderWidth: 2,
                                backgroundColor,
                                borderColor,
                                pointBackgroundColor,
                                pointBorderColor,
                                pointRadius: 2,
                                fill: (o.type !== 'predicted'),
                                borderDash: (o.type === 'predicted' ? [10] : [])
                            }
                        })
                    },
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [strings, stylesheets, token.details.locale, getPatientReadinessSpiderResponse])

    return <div style={{
        height: 500
    }} className={'course-interface'} >
        <canvas className={'chart-bordered'}
            ref={canvasRef} />
    </div>
}

export default RadarChart
